import * as htmlToImage from 'html-to-image';
import PptxGenJS from "pptxgenjs";
import { jsPDF } from "jspdf";
import arrowCreate, { DIRECTION } from 'arrows-svg';

export function CreateScreenshotArrow(startNodeId, endNodeId) {
    const arrow = arrowCreate({
        from: document.getElementById(startNodeId),
        to: document.getElementById(endNodeId),
    });

    document.body.appendChild(arrow.node);

    return arrow;
}

export function CreateArrow(start, end, headDistance, bidirectional) {
    // start / end =
    // {
    //      direction: TOP_LEFT, TOP, TOP_RIGHT, RIGHT, BOTTOM_RIGHT, etc.
    //      node: () => HTMLElement/Node
    //      translation: [x,y] multipliers for the bezier point
    // }
    // headDistance = % (0-100)
    // bidirectional = true/false

    if (!bidirectional) {
        const arrow = arrowCreate({
            from: start,
            to: end,
            head: {
                func: 'thin',
                distance: headDistance,
            }
        });
        return arrow;
    } else {
        const arrow = arrowCreate({
            from: start,
            to: end,
            head: [
                {
                    func: 'thin',
                    distance: headDistance,
                },
                {
                    func: ({ size }) => {
                        return {
                            // manually inverted markup from the 'thin' arrowhead to accomplish the rotation for a backwards arrowhead
                            node: '<g><line x1="10" y1="10" x2="0" y2="0"></line><line x1="0" y1="0" x2="10" y2="-10"></line></g>',
                            width: size,
                            height: size,
                        }
                    },
                    size: 10,
                    distance: .001,
                }
            ]
        });
        return arrow;
    }
}

export function TakeScreenshot(dotNetHelper) {

    document.querySelectorAll('.leader-line > g').forEach((item) => {
        item.style.visibility = 'hidden';
    });

    document.querySelectorAll('.arrow').forEach((item) => {
        item.style.visibility = 'visible';
    });

    // TODO: test other formats for quality, size of PDF, etc.
    htmlToImage.toJpeg(document.body, { quality: 0.1 })
        .then(async (dataUrl) => {

            // Triggering the flash effect
            var overlay = document.getElementById('screenshot-overlay');
            overlay.style.backgroundColor = 'white';

            // Resetting the overlay after a short delay
            setTimeout(function () {
                overlay.style.backgroundColor = 'transparent';
            }, 500);

            document.querySelectorAll('.arrow').forEach((item) => {
                item.style.visibility = 'hidden';
            });

            document.querySelectorAll('.leader-line > g').forEach((item) => {
                item.style.visibility = 'visible';
            });

            await dotNetHelper.invokeMethodAsync('AddScreenshot', dataUrl);
        });
}

export function SaveScreenshotPdf(clientName, projectName, snaps) {
    var doc = CreatePdf('landscape', clientName, projectName);

    for (var i = 0; i < snaps.length; i++) {
        AddScreenshotPage(doc, snaps[i], i + 1)
    }

    doc = AddBackPage(doc);

    var date = new Date().toLocaleDateString('en-CA'); // YYYY-MM-DD
    clientName = clientName.replace(/[,.]/g, '');
    projectName = projectName.replace(/[,.]/g, '');
    doc.save(`${clientName}_${projectName}_${date}.pdf`);
}

export function AddScreenshotPage(doc, imageData, pageNumber) {
    doc.addPage();
    var img = new Image();
    img.src = imageData;

    var aspectRatio = img.naturalWidth / img.naturalHeight;
    // a4 is 297mm x 210mm
    var a4AspectRatio = 297 / 210;

    var xMargin, yMargin;
    var scale, scaledWidth, scaledHeight;

    if (aspectRatio > a4AspectRatio) {
        xMargin = 10;
        scaledWidth = 277;
        scale = scaledWidth / img.naturalWidth;
        scaledHeight = img.naturalHeight * scale;
        yMargin = (210 - scaledHeight) / 2;

        doc.addImage(imageData, 'JPEG', xMargin, yMargin, 277, scaledHeight);
    }
    else {
        yMargin = 10;
        scaledHeight = 190;
        scale = scaledHeight / img.naturalHeight;
        scaledWidth = img.naturalWidth * scale;
        xMargin = (297 - scaledWidth) / 2;

        doc.addImage(snaps[i], 'JPEG', xMargin, yMargin, scaledWidth, 190);
    }

    // black border around the image
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(.5);
    doc.rect(xMargin - .5, yMargin - .5, scaledWidth + 1, scaledHeight + 1);

    // copyright text lower left corner
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text('© ' + new Date().getFullYear() + ' Deloitte Touche Tohmatsu Limited.', 10, doc.internal.pageSize.height - 10);

    //page number lower right corner
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text('' + pageNumber, doc.internal.pageSize.width - 12, doc.internal.pageSize.height - 10);
}

export function CreatePdf(orientation, clientName, projectName) {
    var doc = new jsPDF({
        orientation: orientation,
    });

    return AddCoverPage(doc, clientName, projectName);
}

export function SaveScreenshotPpt(clientName, projectName, snaps) {
    let pptx = new PptxGenJS();
    pptx.layout = 'LAYOUT_WIDE';

    addCoverSlide(pptx, clientName, projectName);

    const margin = 0.5;
    const borderWidth = 1;

    // Width/Height of "LAYOUT_WIDE" in inches
    const slideWidth = 13.333;
    const slideHeight = 7.5;

    snaps.forEach((snap, index) => {
        let snapSlide = pptx.addSlide();
        const imageX = margin;
        const imageY = margin;
        const imageW = slideWidth - 2 * margin;
        const imageH = slideHeight - 2 * margin;

        snapSlide.addImage({
            data: snap,
            x: imageX,
            y: imageY,
            w: imageW,
            h: imageH
        });

        // Borders
        snapSlide.addShape(pptx.shapes.RECTANGLE, {
            x: imageX,
            y: imageY,
            w: imageW,
            h: imageH,
            line: { color: "000000", width: borderWidth },
        });

        // Copyright text
        snapSlide.addText('© ' + new Date().getFullYear() + ' Deloitte Touche Tohmatsu Limited.', {
            x: imageX - .09,
            y: slideHeight - margin - .165,
            w: '20%',
            h: 0.25,
            fontSize: 10,
            color: '000000',
            align: 'left',
            valign: 'bottom'
        });

        // Page numbers
        snapSlide.addText(`${index + 1}`, {
            x: slideWidth - (margin + .2),
            y: slideHeight - (margin + .05),
            w: '2%',
            fontSize: 10,
            color: '000000'
        });
    });

    addBackSlide(pptx);

    clientName = clientName.replace(/[,.]/g, '');
    projectName = projectName.replace(/[,.]/g, '');
    pptx.writeFile({ fileName: `${clientName}_${projectName}_${new Date().toLocaleDateString('en-CA')}.pptx` });
}

export function AddCoverPage(doc, clientName, projectName) {

    // add a black background
    doc.setFillColor(0, 0, 0);
    doc.rect(0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height, 'F');

    // add the logo in the upper right corner
    doc.addImage("images/deloitte-logo-white.png", "PNG", 10, 10, 55, 0);

    // add the cover image centered on the page
    doc.addImage("images/pdf-cover-image.png", "PNG", (doc.internal.pageSize.width / 2) - 55, (doc.internal.pageSize.height / 2) - 55, 110, 110);

    // add the 1845 logo in the lower right corner
    doc.addImage("images/impact-1845-logo.png", "PNG", doc.internal.pageSize.width - 45, doc.internal.pageSize.height - 43, 38, 38);

    // add the text "VCA" in the lower left corner
    doc.setFontSize(24);
    doc.setTextColor(69, 135, 41);
    doc.text('VCA', 10, doc.internal.pageSize.height - 45);

    // add the client name below the VCA text
    doc.setFontSize(24);
    doc.setTextColor(69, 135, 41);
    doc.text(clientName, 10, doc.internal.pageSize.height - 35);

    // add the project name below the client name
    doc.setFontSize(24);
    doc.setTextColor(69, 135, 41);
    doc.text(projectName, 10, doc.internal.pageSize.height - 25);

    // add the date below the project name
    doc.setFontSize(12);
    doc.setTextColor(256, 256, 256);
    doc.text('Snaps export ' + new Date().toLocaleDateString('en-CA'), 10, doc.internal.pageSize.height - 10);

    return doc;
}

export function AddBackPage(doc) {

    doc.addPage();

    // add the Deloitte logo in the top left corner
    doc.addImage("images/deloitte-logo-black.png", "PNG", 10, 10, 55, 0);

    // add the copyright text in the bottom left corner
    doc.setFontSize(9);
    doc.setTextColor(0, 0, 0);
    doc.text('© ' + new Date().getFullYear(), 10, doc.internal.pageSize.height - 40);
    doc.text('Deloitte refers to one or more of Deloitte Touche Tohmatsu Limited, a UK private company limited by guarantee ("DTTL"), its network of member firms, and their related entities. DTTL and each of its member firms are legally separate and independent entities. DTTL (also referred to as "Deloitte Global") does not provide services to clients. In the United States, Deloitte refers to one or more of the US member firms of DTTL, their related entities that operate using the "Deloitte" name in the United States and their respective affiliates. Certain services may not be available to attest clients under the rules and regulations of public accounting. Please see www.deloitte.com/about to learn more about our global network of member firms.',
        10, doc.internal.pageSize.height - 30, { maxWidth: doc.internal.pageSize.width * .60 });

    return doc;
}

function addCoverSlide(pptx, clientName, projectName) {
    let slide = pptx.addSlide();
    slide.background = { fill: "000000" };

    // Add the Deloitte logo in the upper left corner
    slide.addImage({
        path: "images/deloitte-logo-white.png",
        x: 0.5, y: 0.5, w: 2, h: 0.5
    });

    // Add the cover image centered on the page
    slide.addImage({
        path: "images/pdf-cover-image.png",
        x: 4.25,
        y: 1.5,
        w: 4.5, h: 4.5
    });

    // Add the 1845 logo in the lower right corner
    slide.addImage({
        path: "images/impact-1845-logo.png",
        x: 11.5,
        y: 5.9, w: 1.4, h: 1.4
    });

    // Add text labels
    slide.addText('VCA', {
        x: 0.5, y: 5, w: '6.5%', h: .4,
        fontSize: 24, color: "458729",
        align: 'left',
        autoFit: true
    });
    slide.addText(clientName, {
        x: 0.5, y: 5.4, w: '50%', h: .4,
        fontSize: 24, color: "458729",
        align: 'left',
        autoFit: true
    });
    slide.addText(projectName, {
        x: 0.5, y: 5.8, w: '50%', h: .4,
        fontSize: 24, color: "458729",
        align: 'left',
        autoFit: true
    });
    slide.addText(`Snaps export ${new Date().toLocaleDateString('en-CA')}`, { x: 0.5, y: 6.75, w: '15%', h: .25, fontSize: 10, color: "FFFFFF" });
}

function addBackSlide(pptx) {
    let slide = pptx.addSlide();

    slide.addImage({
        path: "images/deloitte-logo-black.png",
        x: 0.5,
        y: 0.5,
        w: 2,
        h: 0.5
    });

    slide.addText('© ' + new Date().getFullYear(), {
        x: 0.5, y: 5.5, w: '5%', h: 0.25,
        fontSize: 9, color: "000000", align: 'left'
    });

    slide.addText('Deloitte refers to one or more of Deloitte Touche Tohmatsu Limited, a UK private company limited by guarantee ("DTTL"), its network of member firms, and their related entities. DTTL and each of its member firms are legally separate and independent entities. DTTL (also referred to as "Deloitte Global") does not provide services to clients. In the United States, Deloitte refers to one or more of the US member firms of DTTL, their related entities that operate using the "Deloitte" name in the United States and their respective affiliates. Certain services may not be available to attest clients under the rules and regulations of public accounting. Please see www.deloitte.com/about to learn more about our global network of member firms.', {
        x: 0.5, y: 6, w: 6.5, h: 1.25,
        fontSize: 9, color: "000000", align: 'left', valign: 'top'
    });
}

